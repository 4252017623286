
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Logout extends Vue {
  mounted () {
    this.$store.dispatch('global/logout')
    this.$router.push('/')
  }
}
